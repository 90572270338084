import toSlug from './toSlag';
import { IPosts, ITags } from '../interfaces/postsInterfaces';


const getAllTags = (data: IPosts[]) => {

    let tagCount: ITags = {}

    data.forEach((post) => {
        if (post.tags) {
            post.tags.forEach((tag) => {
                const formattedTag = toSlug(tag)
                if (formattedTag in tagCount) {
                    tagCount[formattedTag] += 1
                } else {
                    tagCount[formattedTag] = 1
                }
            })
        }
    })
    return tagCount;
}

export { getAllTags }