import React, { FC } from 'react';
import NextLink from 'next/link';
import { useTranslation } from "next-i18next";
import Image from 'next/image';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import { motion } from 'framer-motion';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import Stack from '@mui/material/Stack';

import toSlug from '../../../lib/toSlag';

import { IPosts } from '../../../interfaces/postsInterfaces';
import AllTags from '../../tags/AllTags';
import Pagination from '../posts/Pagination';


interface IPostsWidgetProps {
    locale: string;
    title: string;
    subtitle: string;
    posts: IPosts[];
    limitPage?: number;
    currentPage?: number;
    totalPages?: number;
    pagination?: boolean;
    tag?: string;
}

const PostsWidget: FC<IPostsWidgetProps> = (props: IPostsWidgetProps) => {
    const { title, subtitle, posts, pagination, tag, locale } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    let filteredPosts = posts;

    if (tag) {
        filteredPosts = filteredPosts.filter(
            (post) => post.tags.map((t) => toSlug(t)).includes(tag)
        );
    }

    const limitPage = props.limitPage || Number(process.env.appPageSize);
    const currentPage = props.currentPage || 1;
    const totalPages = props.totalPages || Math.ceil(filteredPosts.length / limitPage);


    const displayPosts = filteredPosts.slice(
        limitPage * (currentPage - 1),
        limitPage * currentPage
    )

    return (
        <Container id='posts' maxWidth='lg' sx={{ py: 2 }}>
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-end"
                spacing={0.5}
            >
                <Typography component='h1' variant='h1' sx={{ mb: 1 }}>
                    {title}
                </Typography>
                <Link variant="h1"
                    title='RSS'
                    href={`/${locale}feed.xml`}
                    target='_blank'
                    sx={{
                        textDecoration: 'none',
                        color: theme.palette.primary.dark,
                        '&:hover': {
                            color: theme.palette.primary.main
                        }
                    }}
                >
                    <RssFeedIcon fontSize='inherit' />
                </Link>
            </Stack>
            <Typography component='p' variant='subtitle1' gutterBottom sx={{ mt: -1 }}>
                {subtitle}
            </Typography>
            <Divider sx={{ mb: 4, borderColor: theme.palette.secondary.dark }} />
            <Grid
                direction={'row'}
                justifyContent='space-around'
                alignItems='stretch'
                container
                spacing={{ xs: 1, md: 2 }}
            >
                <Grid item xs={12} sm={8} md={9} lg={9}>
                    <motion.div
                        layout
                    >
                        <Grid
                            direction={'row'}
                            justifyContent='space-around'
                            alignItems='stretch'
                            container
                            spacing={{ xs: 1, md: 2 }}
                        >
                            {!displayPosts.length && 'No posts found.'}
                            {displayPosts.map(({ slug, title, coverImage, description }, i) => (
                                <React.Fragment key={i}>
                                    {i > 0
                                        ?
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <Divider />
                                        </Grid>
                                        :
                                        null
                                    }

                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <motion.div
                                            initial='hidden'
                                            whileInView='visible'
                                            animate={{ x: -200, opacity: 1 }}
                                            transition={{ duration: 0.7 }}
                                            style={{ height: '100%' }}
                                            viewport={{ once: true }}
                                            variants={{
                                                visible: { opacity: 1, x: 0 },
                                                hidden: { opacity: 0, x: -200 }
                                            }}
                                        >
                                            <NextLink href={`/posts/post/${slug}`} locale={props.locale} passHref>
                                                <Link variant="body1"
                                                    title={title}
                                                    sx={{
                                                        textDecoration: 'none',
                                                        color: theme.palette.primary.dark,
                                                        '> span > img': {
                                                            borderRadius: '4px',
                                                          }
                                                    }}
                                                >
                                                    <Image
                                                        width='450'
                                                        height='300'
                                                        alt={coverImage.alt}
                                                        src={coverImage.url}
                                                    />
                                                </Link>
                                            </NextLink>
                                        </motion.div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8} lg={8}>
                                        <motion.div
                                            initial='hidden'
                                            whileInView='visible'
                                            animate={{ x: -100, opacity: 1, zIndex: 0 }}
                                            transition={{ duration: 0.4 }}
                                            style={{ height: '100%' }}
                                            viewport={{ once: true }}
                                            variants={{
                                                visible: { opacity: 1, x: 0, zIndex: 0 },
                                                hidden: { opacity: 0, x: -100 }
                                            }}
                                        >
                                            <NextLink href={`/posts/post/${slug}`} locale={props.locale} passHref>
                                                <Link variant="body1"
                                                    title={title}
                                                    sx={{
                                                        textDecoration: 'none',
                                                        color: theme.palette.primary.dark,
                                                    }}
                                                >
                                                    <Typography component={'h2'} variant={'h2'}>
                                                        {title}
                                                    </Typography>
                                                    <Typography>
                                                        {description}
                                                    </Typography>
                                                </Link>
                                            </NextLink>
                                        </motion.div>
                                    </Grid>
                                </React.Fragment>
                            ))}
                        </Grid>
                        {totalPages > 1
                            &&
                            <Pagination currentPage={currentPage} totalPages={totalPages} {...props} />
                        }
                    </motion.div>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={3}>
                    <AllTags {...props} />
                </Grid>
            </Grid>
        </Container>
    )
}

export default PostsWidget;