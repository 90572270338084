import React, { FC } from 'react';
import { useTranslation } from "next-i18next";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import NextLink from 'next/link';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';




interface IPaginationProps {
    currentPage: number;
    totalPages: number;
    tag?: string;
}

const Pagination: FC<IPaginationProps> = (props: IPaginationProps) => {
    const theme = useTheme();
    const { currentPage, totalPages, tag } = props;
    const { t } = useTranslation();

    const prevPage = currentPage - 1 > 0
    const nextPage = currentPage + 1 <= totalPages

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
        >
            <NextLink href={currentPage - 1 === 1 ?
                tag ? `/posts/tag/${tag}` : `/posts/`
                :
                tag ? `/posts/tag/${tag}/page/${currentPage - 1}` : `/posts/page/${currentPage - 1}`
                } passHref>
                <Button
                    component={Link}
                    disabled={!prevPage ? true : false}
                >
                    {t('previous')}
                </Button>
            </NextLink>
            <Typography component='span' sx={{ textTransform: 'uppercase' }}>
                {currentPage} of {totalPages}
            </Typography>
            <NextLink href={tag ? `/posts/tag/${tag}/page/${currentPage + 1}` : `/posts/page/${currentPage + 1}`} passHref>
                <Button
                    component={Link}
                    disabled={!nextPage ? true : false}
                >
                    {t('next')}
                </Button>
            </NextLink>
        </Stack>
    )
}


export default Pagination;