import React, { FC } from 'react';
import NextLink from 'next/link';
import Image from 'next/image';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';


import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from "next-i18next";


import { getAllTags } from '../../lib/getTags';
import toSlug from '../../lib/toSlag';

import { IPosts } from '../../interfaces/postsInterfaces';

interface IPostsSectionProps {
    posts: IPosts[];
}

const AllTags: FC<IPostsSectionProps> = (props: IPostsSectionProps) => {
    const { posts } = props;
    const { t } = useTranslation();


    const tags = getAllTags(posts);
    const sortedTags = Object.keys(tags).sort((a, b) => tags[b] - tags[a]);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Box sx={{ mt: 0, px: 2, position: 'sticky', top: '10px' }}>
            <Typography component={'h5'} variant={'h6'} gutterBottom>
                {t('postsByTags')}
            </Typography>
            <Box sx={{ textAlign: 'justify', display: 'flex', flexWrap: 'wrap', mb:1 }}>
                {Object.keys(tags).length === 0 && 'No tags found.'}
                {sortedTags.map((tag) => {
                    return (
                        <NextLink key={tag} href={`/posts/tag/${tag}`} passHref>
                            <Link
                                variant="body2"
                                sx={{
                                    pr: 1,
                                    textDecoration: 'none',
                                    color: theme.palette.primary.dark,
                                    '&:hover': {
                                        color: theme.palette.primary.main
                                    }
                                }}
                            >
                                {`${tag}`}
                                <Typography component={'span'} variant="body2" sx={{ color: theme.palette.primary.dark }} >
                                    ({tags[tag]})
                                </Typography>
                            </Link>
                        </NextLink>
                    )
                })}
            </Box>
            <NextLink href='/posts' passHref>
                    <Link variant="body1"
                        sx={{
                            textDecoration: 'none',
                            color: theme.palette.primary.dark,
                            '&:hover': {
                                color: theme.palette.primary.main
                            }
                        }}
                    >
                        {t('allPosts')}
                    </Link>
                </NextLink>
        </Box>
    )
}

export default AllTags;